import axiosInstance from 'apis/axiosInstance';

export const getMe = async (token: string) => {
  try {
    const response = await axiosInstance.get('/auth/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};
