import { AuthProvider } from 'context/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';

import routes, { renderRoutes } from 'routers/routes';

function App() {
  return (
    <>
      <Router>
        <AuthProvider>{renderRoutes(routes)}</AuthProvider>
      </Router>
    </>
  );
}

export default App;
