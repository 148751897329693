import cookies from 'js-cookie';

export const getCookie = (name: any) => cookies.get(name);
export const setCookie = (name: any, value: any, expires = 1, options: object = {}) =>
  cookies.set(name, value, { expires });
export const removeCookie = (name: any) => cookies.remove(name);
export const removeAllCookies = () => {
  Object.keys(cookies.get()).forEach((key) => {
    cookies.remove(key);
  });
};
