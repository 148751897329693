// src/components/DynamicBreadcrumb.tsx
import { Breadcrumb } from 'antd';
import { RoutersBreadCrumb } from 'constants/routers';
import { t } from 'i18next';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const findRouteName = (path: string | null): string | null => {
  if (!path) return null;

  // Check for dynamic segments in the URL (e.g., containing an ID)
  const dynamicSegment = path.match(/\/([^\/]+)\/(\d+)/);
  if (dynamicSegment) {
    // Construct the path for dynamic route matching
    const baseRoute = `/job-management/${dynamicSegment[1]}/:id`;
    const route = RoutersBreadCrumb.find((r) => r.path === baseRoute);
    if (route) {
      return `${t(route.breadcrumbName)}`; // Return the route name with the ID
    }
  }

  // Find a route that exactly matches the static path
  const route = RoutersBreadCrumb.find((r) => path === r.path);
  return route ? t(route.breadcrumbName) : null;
};

const DynamicBreadcrumb: React.FC = () => {
  const location = useLocation();

  if (location.pathname === '/') {
    return null; // No breadcrumbs for the home route
  }

  const pathSnippets = location.pathname.split('/').filter((i) => i);
  let dynamicDetected = false; // Flag to check if a dynamic route has been detected

  const breadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      if (dynamicDetected && url.includes('/detail/')) {
        return null; // Skip adding breadcrumb for '/detail/' if a dynamic ID route is already handled
      }

      const breadcrumbName = findRouteName(url);
      if (breadcrumbName && breadcrumbName.includes('page.jobs.titleDetail')) {
        dynamicDetected = true; // Set flag if dynamic route is detected
      }

      return breadcrumbName
        ? {
            key: url,
            breadcrumbName: t(breadcrumbName),
            path: url
          }
        : null;
    })
    .filter(Boolean);

  const itemList = [
    {
      key: 'home',
      breadcrumbName: 'Home',
      path: '/'
    },
    ...breadcrumbItems
  ].map((item) => ({
    title: item?.breadcrumbName,
    key: item?.key,
    path: item?.path
  }));

  function itemRender(currentRoute: any, params: any, items: any, paths: any) {
    const isLast = currentRoute?.path === items[itemList.length - 1]?.path;
    return isLast ? <span>{currentRoute.title}</span> : <Link to={`${paths.join('/')}`}>{currentRoute.title}</Link>;
  }

  return <Breadcrumb className="pt-3 pb-1 px-6  " itemRender={itemRender} items={itemList} />;
};

export default DynamicBreadcrumb;
