import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';

import 'styles/index.scss';
import 'ckeditor5/ckeditor5.css';
import App from './App';
import './i18n';
import { store } from './redux/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#D7242F',
          borderRadius: 1,
          colorText: '#000000d9',
          colorTextBase: '#000000d9',
          fontSize: 16,
          fontFamily: '"Roboto", sans-serif'
        }
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);
