import { Menu } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { useMenuList } from 'constants/menu';
import { useEffect, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

function TCommonSidebar() {
  const { user } = useAuth();
  const userRole = user?.role;
  const menuList = useMenuList();
  const navi = useNavigate();
  const location = useLocation();
  const [defaultSelectedKeys, setDefautSelectedKeys] = useState<string>(location.pathname);

  const filterMenuByRole = () => {
    switch (userRole) {
      case 'admin':
        return menuList;

      case 'human_resource':
        return menuList.filter((item) =>
          [
            '/job-management',
            '/articles-management',
            '/candidates-management',
            '/user-management',
            '/marathon-event'
          ].includes(item.key)
        );

      case 'training_coordinator':
        return menuList.filter((item) => ['/training-management', '/hashtag-management'].includes(item.key));

      default:
        return [];
    }
  };

  const roleBasedMenu = filterMenuByRole();

  const onSelect = (item: MenuItemType) => {
    navi(item.key as To);
  };

  useEffect(() => {
    setDefautSelectedKeys(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="header__logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[defaultSelectedKeys]} onSelect={onSelect} items={roleBasedMenu} />
    </>
  );
}

export default TCommonSidebar;
