import { Spin } from 'antd';

function Loading() {
  return (
    <div className="w-full flex items-center justify-center loading">
      <Spin spinning={true}></Spin>
    </div>
  );
}

export default Loading;
