import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import set from 'lodash/set';
import get from 'lodash/get';
import { getCookie } from 'utils/cookies';
import { BASE_API_URL, CookieKeys } from 'constants/services';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL: BASE_API_URL,
  timeout: 20000,
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  }
});

axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>) => {
    const token = getCookie(CookieKeys.Token);
    if (!token) {
      return request;
    }
    set(request, 'headers.Authorization', `Bearer ${token}`);
    return request;
  },
  (_error: any) => {
    console.log('🚀 ~ axiosInstance.interceptors.request.use ~ _error:', _error);
    const errorResponse = {
      status: null,
      message: null,
      errors: null
    };
    return Promise.reject(errorResponse);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.href = '/login';
      return Promise.reject(error);
    }
    const errorResponse = {
      status: get(error, 'response.status', null),
      message: get(error, 'response.data.message', null),
      errors: get(error, 'response.data.errors', null)
    };
    return Promise.reject(errorResponse);
  }
);

export default axiosInstance;
