export const CookieKeys = {
  Token: 'Token',
  RefreshToken: 'RefreshToken'
};

export const BASE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;
// export const BASE_API_URL = `https://api.home-page-javis.codeaplha.biz/api`;






