import Routers from 'constants/routers';
import React, { Fragment, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TCommonLayout from 'components/Layout';
import Loading from 'components/Loading';
export interface IRoutesState {
  exact?: boolean;
  path?: string;
  guard?: React.FC<any>;
  layout?: React.FC<any>;
  component?: any;
  routes?: IRoutesState[];
}

export const renderRoutes = (routes: IRoutesState[]) => (
  <Suspense fallback={<Loading />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component />}</Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes: IRoutesState[] = [
  {
    path: Routers.Login,
    component: lazy(() => import('pages/Login'))
  },
  {
    path: Routers.All,
    layout: TCommonLayout,
    routes: [
      {
        path: Routers.Dashboard,
        component: lazy(() => import('pages/Jobs'))
      },
      {
        path: Routers.Jobs,
        component: lazy(() => import('pages/Jobs'))
      },

      {
        path: Routers['CreateJob'],
        component: lazy(() => import('pages/Jobs/create'))
      },
      {
        path: `${Routers['DetailJob']}/:id`,
        component: lazy(() => import('pages/Jobs/detail'))
      },
      {
        path: Routers.Articles,
        component: lazy(() => import('pages/Articles'))
      },
      {
        path: Routers.Candidates,
        component: lazy(() => import('pages/Candidates'))
      },
      {
        path: `${Routers['CreateMeeting']}/:id`,
        component: lazy(() => import('pages/Candidates/create/[id]'))
      },
      {
        path: Routers['CreateArticle'],
        component: lazy(() => import('pages/Articles/create'))
      },
      {
        path: `${Routers['DetailArticle']}/:id`,
        component: lazy(() => import('pages/Articles/detail'))
      },
      {
        path: `${Routers['InfoArticle']}/:id`,
        component: lazy(() => import('pages/Articles/info'))
      },
      // User Management
      {
        path: Routers.Users,
        component: lazy(() => import('pages/Users'))
      },
      // Training Mangement
      {
        path: Routers.Training,
        component: lazy(() => import('pages/Training'))
      },
      {
        path: `${Routers['TrainingView']}/:id`,
        component: lazy(() => import('pages/Training/view'))
      },
      {
        path: Routers.Hashtags,
        component: lazy(() => import('pages/Hashtags'))
      },
      {
        path: Routers.Marathon,
        component: lazy(() => import('pages/MarathonEvent'))
      },
      {
        path: '*',
        component: () => <Navigate to={Routers.NotFound} replace />
      }
    ]
  }
];

export default routes;
