export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRED_TIME = 'expiredTime';
export const BREADCRUMB_NAME = 'breadcrumbName';
export const ID_CANDIDATE = 'idCandidate';

export enum CANDIDATE_STATUS {
  FAIL = 'FAIL',
  PASS = 'PASS_CV',
  CHECKING = 'CHECKING_CV',
  REJECT = 'REJECT'
}

export const getCandidateValue = (val: string) => {
  const candidateStatus: any = {
    FAIL: 'Fail',
    PASS: 'Pass',
    CHECKING_CV: 'Checking_CV',
    UNSATISFACTORY: 'Unsatisfactory',
    OFFER: 'Offer',
    WAITING_FOR_INTERVIEW: 'Waiting for interview'
  };
  if (val) return candidateStatus[val.toString()] ;
};

export const getCandidateValue2 = (val: string) => {
  const candidateStatus: any = {
    PENDING: 'Pending',
    PASS_CV: 'Pass CV',
    FAIL_CV: 'Fail CV',
    SCHEDULED_INTERVIEW: 'Shedule Interview',
    INTERVIEWED: 'Interviewed',
    PASS_INTERVIEW: 'Pass Interview',
    FAIL_INTERVIEW: 'Fail Interview',
    REJECT: 'Reject'
  };
  if (val) return candidateStatus[val.toString()];
};

export enum CANDIDATE_STATUS_NEW {
  PENDING = 'PENDING',
  PASS_CV = 'PASS_CV',
  FAIL_CV = 'FAIL_CV',
  SCHEDULED_INTERVIEW = 'SCHEDULED_INTERVIEW',
  INTERVIEWED = 'INTERVIEWED',
  PASS_INTERVIEW = 'PASS_INTERVIEW',
  FAIL_INTERVIEW = 'FAIL_INTERVIEW',
  REJECT = 'REJECT'
}
