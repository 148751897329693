import { notification } from 'antd';
import { IconType } from 'antd/es/notification/interface';

function noti(type: IconType, message: string) {
  return notification.open({
    type,
    message,
    duration: 2
  });
}

export default noti;
