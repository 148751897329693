
function IconSideMenuSwitcher() {
    return (
        <svg width="14" height="70" viewBox="0 0 14 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.888672L14 8.88867V58.8887L0 66.8887V0.888672Z" fill="white" />
            <path
                d="M9.24982 31.2173V30.2511C9.24982 30.1673 9.15357 30.1211 9.08857 30.1723L3.45357 34.5736C3.4057 34.6108 3.36696 34.6585 3.34031 34.713C3.31366 34.7675 3.2998 34.8273 3.2998 34.888C3.2998 34.9486 3.31366 35.0085 3.34031 35.0629C3.36696 35.1174 3.4057 35.1651 3.45357 35.2023L9.08857 39.6036C9.15482 39.6548 9.24982 39.6086 9.24982 39.5248V38.5586C9.24982 38.4973 9.22107 38.4386 9.17357 38.4011L4.67357 34.8886L9.17357 31.3748C9.22107 31.3373 9.24982 31.2786 9.24982 31.2173Z"
                fill="black"
                fillOpacity="0.45"
            />
        </svg>
    );
}

export default IconSideMenuSwitcher;
