import { Layout } from 'antd';
import IconSideMenuSwitcher from 'assets/Icon/IconSideMenuSwitcher';
import DynamicBreadcrumb from 'components/Breadcrumb';
import { PATH_UNUSED_BREADCRUMB } from 'constants/routers';
import { CookieKeys } from 'constants/services';
import React, { ReactNode, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { getCookie } from 'utils/cookies';
import TCommonHeader from './Header';
import TCommonSidebar from './Sidebar';

const { Content, Header, Sider } = Layout;
const TCommonLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const isAuthenticated = () => {
    return !!getCookie(CookieKeys.Token);
  };

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <Layout>
      <Sider className="relative" trigger={null} collapsible collapsed={collapsed} width={250}>
        <TCommonSidebar />
        <div className="sider__switch absolute" onClick={() => setCollapsed(!collapsed)}>
          <IconSideMenuSwitcher />
        </div>
        {!collapsed && (
          <div className="absolute right-0 left-0 bottom-0 flex items-center h-14 text-xs justify-center text-black border-t border-gray border-opacity-60">
            <span>{t('common.label.copyright')}</span>
          </div>
        )}
      </Sider>
      <Layout>
        <Header style={{ padding: '0 10px' }} className="bg-red">
          <TCommonHeader />
        </Header>
        <Content
          style={{
            maxHeight: 'calc(100vh - 56px)',
            minHeight: 'calc(100vh - 56px)',
            overflowY: 'auto'
          }}
        >
          {!PATH_UNUSED_BREADCRUMB.includes(location.pathname) && <DynamicBreadcrumb />}

          <div>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default memo(TCommonLayout);
