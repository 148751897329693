import IconPaper from 'assets/Icon/IconPaper';
import { useTranslation } from 'react-i18next';
import IconNews from 'assets/Icon/IconNews';
import IconMenuTraining from 'assets/Icon/IconMenuTraining';
import IconMenuUser from 'assets/Icon/IconMenuUser';
import IconMarathonEvent from 'assets/Icon/IconMarathonEvent';
import IconHashtag from 'assets/Icon/IconHashtag';

import IconCandidatesMenu from 'assets/Icon/IconCandidatesMenu';

export const useMenuList = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('menu.label.jobsManagement'),
      path: '/job-management',
      key: '/job-management',
      icon: <IconPaper />
    },
    {
      label: 'Articles Management',
      path: '/articles-management',
      key: '/articles-management',
      icon: <IconNews />
    },
    {
      label: 'Candidates',
      path: '/candidates-management',
      key: '/candidates-management',
      icon: <IconCandidatesMenu />
    },
    {
      label: 'User Management',
      path: '/user-management',
      key: '/user-management',
      icon: <IconMenuUser />
    },
    {
      label: 'Training Management',
      path: '/training-management',
      key: '/training-management',
      icon: <IconMenuTraining />
    },
    {
      label: 'Hashtag Management',
      path: '/hashtag-management',
      key: '/hashtag-management',
      icon: <IconHashtag />
    },
    {
      label: 'Marathon Event',
      path: '/marathon-event',
      key: '/marathon-event',
      icon: <IconMarathonEvent />
    }
  ];
};
