import React from 'react';

function IconMarathonEvent({ color = '#666666', className = '', onClick = () => {}, width = 24, height = 24 }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7104_15548)">
        <path
          d="M21.5156 2.60338C21.4665 2.60138 21.4174 2.60107 21.3683 2.60244H19.5177V1.01206C19.5178 0.375912 18.9105 0 18.2744 0H16.5684C15.9322 0 15.4696 0.375912 15.4696 1.01206V2.60239H8.52982V1.01206C8.52982 0.375912 8.06718 0 7.43103 0H5.72498C5.08888 0 4.48162 0.375912 4.48162 1.01206V2.60239H2.63106C1.44975 2.56945 0.465412 3.50043 0.432481 4.68169C0.431098 4.73073 0.43143 4.77988 0.433422 4.82891V21.5421C0.433422 22.8144 1.3587 23.9999 2.631 23.9999H21.3684C22.6407 23.9999 23.566 22.8144 23.566 21.5421V4.82891C23.6144 3.64815 22.6963 2.65175 21.5156 2.60338ZM16.5684 1.15663H18.3612V4.33733H16.5684V1.15663ZM5.72503 1.15663H7.37324V4.33733H5.72503V1.15663ZM22.4094 21.5421C22.4094 22.1783 22.0045 22.8433 21.3684 22.8433H2.63106C1.99491 22.8433 1.59011 22.1783 1.59011 21.5421V9.25302H22.4094V21.5421ZM22.4094 4.82891V8.09639H1.59005V4.82891C1.54107 4.28818 1.93973 3.8101 2.48046 3.76112C2.53052 3.75659 2.58084 3.7559 2.631 3.75907H4.48162V4.39522C4.48162 5.03137 5.08882 5.49401 5.72498 5.49401H7.43103C8.02168 5.51023 8.5136 5.04449 8.52982 4.45383C8.53037 4.43429 8.53037 4.41476 8.52982 4.39522V3.75901H15.4696V4.39516C15.4534 4.98582 15.9191 5.47774 16.5098 5.49395C16.5293 5.49451 16.5488 5.49451 16.5684 5.49395H18.2744C18.9106 5.49395 19.5178 5.03131 19.5178 4.39516V3.75901H21.3684C21.9103 3.72486 22.3773 4.13648 22.4114 4.67837C22.4146 4.72853 22.4139 4.77886 22.4094 4.82891Z"
          fill="#D7242F"
        />
        <path
          d="M8.7898 17.1467L8.26932 20.0383C8.24872 20.1623 8.26911 20.2897 8.32738 20.4011C8.47543 20.6841 8.82489 20.7935 9.10787 20.6455L11.7103 19.2864L14.3126 20.6455L14.5729 20.7033C14.6984 20.7055 14.8208 20.6647 14.9198 20.5876C15.0047 20.5257 15.071 20.4418 15.1118 20.345C15.1525 20.2482 15.1662 20.1422 15.1512 20.0382L14.6307 17.1466L16.7416 15.1226C16.8158 15.0423 16.8682 14.9443 16.8935 14.8379C16.9188 14.7316 16.9163 14.6205 16.8861 14.5154C16.8478 14.4182 16.7852 14.3326 16.7041 14.2667C16.6231 14.2009 16.5264 14.1571 16.4235 14.1395L13.5319 13.7057L12.2307 11.0744C12.174 10.9573 12.0795 10.8627 11.9624 10.806C11.6749 10.6668 11.329 10.787 11.1898 11.0744L9.88859 13.7057L6.99702 14.1395C6.89409 14.157 6.79743 14.2009 6.71639 14.2667C6.63535 14.3326 6.57266 14.4182 6.53438 14.5154C6.50426 14.6205 6.50174 14.7316 6.52706 14.8379C6.55238 14.9443 6.60469 15.0423 6.67894 15.1226L8.7898 17.1467ZM10.3802 14.8045C10.4694 14.7912 10.5537 14.7548 10.6247 14.6991C10.6956 14.6433 10.7509 14.57 10.785 14.4865L11.7103 12.6359L12.6355 14.4865C12.6696 14.57 12.7249 14.6433 12.7959 14.6991C12.8668 14.7548 12.9511 14.7912 13.0403 14.8045L15.0934 15.0937L13.6187 16.5105C13.5509 16.5776 13.4999 16.6597 13.4698 16.7501C13.4396 16.8405 13.4312 16.9367 13.4451 17.031L13.7921 19.084L11.9704 18.1009L11.7102 18.0431L11.45 18.1009L9.62829 19.084L9.97526 17.031C9.98922 16.9367 9.98079 16.8405 9.95065 16.7501C9.9205 16.6596 9.86949 16.5776 9.80175 16.5105L8.32704 15.0937L10.3802 14.8045Z"
          fill="#D7242F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7104_15548">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconMarathonEvent;
