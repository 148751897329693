import React, { createContext, useState, useContext, useEffect } from 'react';
import { getMe } from 'service/getMe';
import { IUserList } from 'models/users';
import noti from 'libs/notification';
import { getCookie, setCookie, removeCookie } from 'utils/cookies';
import { useLocation, useNavigate } from 'react-router-dom';
import Routers from 'constants/routers';

interface AuthContextType {
  user: IUserList | null;
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
  setUser: (user: IUserList | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IUserList | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navi = useNavigate();
  const location = useLocation();

  const login = async (token: string) => {
    setCookie('token', token, 1, { path: '/' });
    setIsAuthenticated(true);

    try {
      const userData = await getMe(token);

      if (userData.role === 'admin') {
        setUser(userData);
        noti('success', 'Login successfully');
        const from = location.state?.from || Routers.Jobs;
        navi(from);
      } else if (userData.role === 'human_resource') {
        setUser(userData);
        noti('success', 'Login successfully');
        const from = location.state?.from || Routers.Jobs;
        navi(from);
      } else if (userData.role === 'training_coordinator') {
        setUser(userData);
        noti('success', 'Login successfully');
        const from = location.state?.from || Routers.Training;
        navi(from);
      } else if (userData.role === 'user') {
        noti('error', 'You can not login to admin site!');
        logout();
      } else {
        noti('error', 'Invalid account. Contact admin to receive support!');
      }
    } catch (error) {
      console.log('Error fetching user data', error);
    }
  };

  const logout = () => {
    removeCookie('token');
    setUser(null);
    setIsAuthenticated(false);
  };

  const fetchUserData = async (token: string) => {
    try {
      const userData = await getMe(token);

      if (userData.role === 'admin') {
        setUser(userData);
        noti('success', 'Login successfully');
      } else if (userData.role === 'human_resource') {
        setUser(userData);
        noti('success', 'Login successfully');
      } else if (userData.role === 'training_coordinator') {
        setUser(userData);
        noti('success', 'Login successfully');
        const from = location.state?.from || Routers.Training;
        navi(from);
      } else if (userData.role === 'user') {
        noti('error', 'You can not login to admin site!');
        logout();
      } else {
        logout();
        noti('error', 'Invalid account. Contact admin to receive support!');
      }
    } catch (error) {
      console.log('Error fetching user data', error);
      logout();
    }
  };

  useEffect(() => {
    const token = getCookie('token');
    if (token && !user) {
      setIsAuthenticated(true);
      fetchUserData(token);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout, setUser }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
