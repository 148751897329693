import React from 'react';

function IconInfo() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#D7242F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0808 15.9748L14.9901 12.8436C14.9025 12.7872 14.8031 12.7519 14.6995 12.7404L12.5526 12.4498C12.3925 12.4268 12.2292 12.4576 12.0884 12.5373C11.9476 12.6171 11.8372 12.7412 11.7745 12.8904L10.4901 15.7686C10.4298 15.9024 10.41 16.0511 10.4334 16.1961C10.4567 16.3411 10.5221 16.476 10.6214 16.5842L12.3839 18.4873C12.4636 18.5749 12.5219 18.6797 12.5545 18.7935C12.587 18.9073 12.5928 19.0271 12.5714 19.1436L12.2058 20.9998"
        stroke="#D7242F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.09281 5.2124L5.24906 7.1999C5.17557 7.37635 5.17222 7.57419 5.23969 7.75303L6.31781 10.6218C6.35995 10.7413 6.43207 10.8481 6.52728 10.9318C6.62248 11.0155 6.73758 11.0734 6.86156 11.0999L8.86781 11.5312C8.97917 11.5537 9.08374 11.6018 9.17324 11.6718C9.26275 11.7418 9.33471 11.8316 9.38344 11.9343L9.73969 12.6749C9.80299 12.8001 9.89935 12.9056 10.0183 12.98C10.1373 13.0544 10.2744 13.0948 10.4147 13.0968H11.6803"
        stroke="#D7242F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2973 3.29053L15.1691 4.86553C15.2496 5.0136 15.2786 5.18425 15.2514 5.35058C15.2243 5.51692 15.1426 5.6695 15.0191 5.78428L12.4973 8.0624C12.455 8.10271 12.4078 8.13738 12.3566 8.16553L11.2035 8.80303C11.0936 8.86135 10.9717 8.89344 10.8473 8.89678H8.84102C8.69322 8.89753 8.54886 8.94139 8.42562 9.02299C8.30239 9.10459 8.20565 9.22038 8.14727 9.35615L7.36914 11.203"
        stroke="#D7242F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconInfo;
